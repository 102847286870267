import React, { Fragment } from "react";
import { Link } from "gatsby";
import NavigationItems from "../Content/Navigation/Navigation";

// Components
import Logo from "./Logo";

// Global styles
import * as UIkit from "uikit";

// markup
class Header extends React.Component {
  state = {
    windowWidth: undefined,
    timer: undefined,
    overlay: false,
  };

  handleResizex = (): void => {
    UIkit.modal("#mobile-nav").hide();
  };

  handleResize = (windowWidth?: number): any => {
    clearTimeout(this.state.timer);
    const currentWidth = window.innerWidth;
    const triggerWidth = 1200;

    // Prevent mobile chrome from triggering resize when nothing happens
    // Close mobile menu to prevent desktop overflow when resizing window (edge case)
    if (currentWidth != windowWidth && currentWidth >= triggerWidth) {
      this.setState({
        windowWidth: window.innerWidth,
        timer: setTimeout(this.handleResizex, 100),
      });
    }
    // Close custom overlay to prevent mobile overflow when resizing window (edge case)
    if (currentWidth != windowWidth && currentWidth <= triggerWidth) {
      this.setOverlayState(false);
    }
  };

  setOverlayState = (flag: boolean): void => {
    this.setState({
      overlay: flag,
    });
  };

  uikitNavOverlay = (): void => {
    // Custom overlay
    UIkit.util.on("#navigation", "beforeshow", () => {
      this.setOverlayState(true);
    });
    UIkit.util.on("#navigation", "hidden", () => {
      this.setOverlayState(false);
    });
  };

  uikitModal = (): void => {
    const url = new URL(window.location as any);
    const modalStatus = url.searchParams.get("productSelector");
    const modalId = "#js-product-selector-app"

    if (modalStatus) UIkit.modal(modalId).show();

    UIkit.util.on(modalId, "beforeshow", () => {
      url.searchParams.set('productSelector', 'true');
      history.pushState({}, "Product Selector", url)
    });
    UIkit.util.on(modalId, "beforehide", () => {
      url.searchParams.delete('productSelector');
      history.pushState({}, "", url)
    });
  };

  uikitScroll = (): void => {
    // To support the funky hash in the mega menu
    // Callback to close the mobile menu
    UIkit.util.on('[id^=js-scroll]', 'beforescroll', () => {
      UIkit.modal("#mobile-nav").hide();
    });
  };

  componentDidMount(): void {
    if (typeof window !== "undefined") {
      const windowWidth = window.innerWidth;

      this.uikitNavOverlay();
      this.uikitModal();
      this.uikitScroll();
      window.addEventListener("resize", () => this.handleResize(windowWidth));
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", () => this.handleResize);
  }

  render(): JSX.Element {
    return (
      <Fragment>
        <header id="header" className="c-navigation" style={{ zIndex: 100 }}>
          <section className="uk-section uk-section-secondary uk-padding-remove-vertical uk-light uk-preserve-color uk-visible@xl">
            <div className="uk-container">
              <nav
                className="uk-navbar-container uk-navbar uk-navbar-transparent c-text-2xsmall c-navigation__quicklinks"
                aria-label="secondary"
              >
                <div className="uk-navbar-left">
                  <a
                    href="https://www.conexbanninger.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="14"
                      height="14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 7a6 6 0 01-6 6m6-6a6 6 0 00-6-6m6 6H1m6 6a6 6 0 01-6-6m6 6c1.105 0 2-2.687 2-6s-.895-6-2-6m0 12c-1.105 0-2-2.687-2-6s.895-6 2-6m0 0a6 6 0 00-6 6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Conex Bänninger Worldwide
                  </a>
                </div>
                <div className="uk-navbar-right">
                  <Link to="/about" className="uk-margin-medium-left">
                    <svg
                      width="14"
                      height="14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.667 9.667H7V7h-.667M7 4.333h.007M13 7A6 6 0 111 7a6 6 0 0112 0z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    About Us
                  </Link>
                  <Link to="/contact" className="uk-margin-medium-left">
                    <svg
                      width="14"
                      height="12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 3.333L6.26 6.84a1.333 1.333 0 001.48 0L13 3.333M2.333 10.666h9.334A1.333 1.333 0 0013 9.333V2.666a1.334 1.334 0 00-1.333-1.333H2.333A1.333 1.333 0 001 2.666v6.667a1.333 1.333 0 001.333 1.333z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    Contact
                  </Link>
                </div>
              </nav>
            </div>
          </section>
          <section className="uk-section uk-section-xsmall uk-background-default uk-dark uk-position-relative uk-position-z-index">
            <div className="uk-container">
              <div>
                <div className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap">
                  <Logo />
                  <div className="uk-flex uk-flex-middle">
                    <nav
                      className="uk-navbar-container uk-preserve-color uk-navbar-transparent uk-visible@xl"
                      uk-navbar="dropbar: true"
                      id="navigation"
                      aria-label="primary"
                    >
                      <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                          <NavigationItems />
                        </ul>
                      </div>
                    </nav>
                    <div className="uk-navbar-dropbar c-position-top-100"></div>
                    <a
                      className="uk-navbar-toggle uk-hidden@xl"
                      uk-toggle="target: #mobile-nav"
                      uk-navbar-toggle-icon="true"
                      title="Toggle mobile navigation"
                      href="#mobile-nav"
                    >
                      <span className="c-visually__hidden">Navigation</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="c-slideouts">
            <div
              uk-modal="truestack: true"
              className="uk-hidden@xl uk-modal-full"
              id="mobile-nav"
            >
              <div className="uk-modal-dialog uk-height-1-1 uk-background-muted">
                <section className="uk-section uk-section-xsmall uk-background-default uk-dark uk-preserve-color ">
                  <div className="uk-container uk-flex uk-flex-center uk-flex-column uk-width-1-1">
                    <Logo />
                    <button
                      className="uk-modal-close-full uk-close-large"
                      type="button"
                      uk-close="true"
                    >
                      <span className="c-visually__hidden">Close</span>
                    </button>
                  </div>
                </section>
                <nav className="uk-dark uk-background-muted">
                  <ul className="uk-nav uk-nav-default uk-nav-parent-icon">
                    <NavigationItems />
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <div
          className={`uk-offcanvas uk-offcanvas-overlay uk-open uk-animation-fade uk-animation-fast ${
            this.state.overlay && "uk-display-block"
          }`}
          tabIndex={-1}
          style={{ zIndex: 99 }}
        />
      </Fragment>
    );
  }
}

export default Header;
