import React, { Fragment } from "react";

// Components
import Footer from "./Footer";
import Header from "./Header";
import SkipLinks from "../Content/SkipLinks/Index";

// Global styles
import * as UIkit from "uikit";
import * as Icons from "uikit/dist/js/uikit-icons.js";
import "../../styles/core.scss";

interface Props {
  staticHtml?: React.ReactNode;
}

class Layout extends React.Component<Props> {
  state = {
    ready: false,
  };

  componentDidMount(): void {
    if (typeof window !== "undefined") {
      UIkit.use(Icons);
      this.setState({ ready: true });
    }
  }

  render(): JSX.Element {
    const { children, staticHtml } = this.props;

    return (
      <Fragment>
        {this.state.ready && (
          <div key="wrapper" id="top" className="site-wrap">
            <SkipLinks />
            <Header />
            <main id="main" className="c-main">
              {children}
            </main>
            <Footer />
          </div>
        )}
        {!this.state.ready && (
          <div className="uk-hidden">
            {/* Gatsby will print this into the static build */}
            {staticHtml}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Layout;
