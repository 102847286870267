import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql, withPrefix } from "gatsby";

interface SeoProps {
  readonly title: string;
  readonly description: string;
  readonly image?: string;
}

const Seo: React.FC<SeoProps> = ({ title, description, image }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  const schemaOrganization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: siteUrl,
    logo: siteUrl + "/",
  };

  return (
    <Helmet titleTemplate={titleTemplate} defer={false}>
      {/* html attributes */}
      <html lang="en-US" />

      {/* standard meta elements */}
      <title itemProp="name" lang="en-US">
        {seo.title}
      </title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* structured data */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrganization)}
      </script>

      {/* Open Graph data */}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content="website" />
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={withPrefix(seo.image)} />}

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.url && <meta property="twitter:url" content={seo.url} />}
      {seo.image && <meta name="twitter:image" content={withPrefix(seo.image)} />}
    </Helmet>
  );
};

export default Seo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`;
