import React from "react";
import { withPrefix } from "gatsby";

interface Props {
  id?: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
  subtitle?: string | null;
  viewMore?: string | null;
  viewMoreLabel?: string;
  pageUrl: string;
  pageUrlLabel?: string;
}

const NavbarDropdownItem: React.FC<Props> = (props) => {
  const {
    id,
    imgSrc,
    imgAlt,
    title,
    subtitle,
    viewMore,
    // viewMoreLabel,
    pageUrl,
    // pageUrlLabel,
  } = props;
  const windowUrl =
    typeof window !== "undefined" ? window.location.pathname : "";
  const url = windowUrl.replace(/\/$/, "");
  const PagePath = pageUrl ? pageUrl.split("#")[0].replace(/\/$/, "") : null;

  return (
    <div className="c-navigation__item uk-flex uk-flex-column">
      <div className="uk-flex uk-flex-middle">
        <div className="uk-width-1-1 uk-flex uk-flex-middle c-navigation__list-content">
          {imgSrc && (
            <img
              uk-img=""
              data-src={withPrefix(imgSrc)}
              alt={imgAlt}
              width="90"
              height="90"
            />
          )}
          <div className="uk-margin-left">
            <h3
              className="uk-margin-remove uk-text-bold uk-h6"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {subtitle && (
              <p
                className="uk-text-small uk-margin-remove"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </div>
        </div>
        <div className="c-navigation__list-cta">
          {viewMore && (
            <a
              href={viewMore}
              className="uk-button uk-button-small uk-button-primary uk-margin-small-bottom"
            >
              View Range
            </a>
          )}
          {pageUrl && (
            <a
              id={id}
              href={pageUrl}
              className={`uk-button uk-button-small uk-button-${
                viewMore ? "secondary" : "primary"
              }`}
              {...(PagePath === url ? { "uk-scroll": "" } : "")}
            >
              Learn More {}
              <span
                className="c-visually__hidden"
                dangerouslySetInnerHTML={{ __html: `About ${title}` }}
              />
            </a>
          )}
        </div>
      </div>
      <div className="c-navigation__divider" />
    </div>
  );
};

export default NavbarDropdownItem;
