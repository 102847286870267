import React from "react";
import { Link, withPrefix } from "gatsby";

export interface DropdownItem {
  heading: string;
  subheading: string;
  imagePath: string;
  imageAlt: string;
  linkText: string;
  linkPath: string;
}

interface Props {
  linkTextDesktop: string;
  linkTextMobile?: string;
  linkPath: string;
  items?: DropdownItem[] | [];
  linkHighlight?: boolean | false;
}

const NavbarDropdownArticle: React.FC<Props> = ({
  linkTextDesktop,
  linkTextMobile,
  linkPath,
  items,
  linkHighlight,
}) => {
  return (
    <li>
      {items?.length ? (
        <button>
          <span className="c-visually__hidden">Toggle Menu for:</span>{" "}
          {linkTextDesktop}
        </button>
      ) : (
        <Link to={linkPath} className={`c-navbar-link ${linkHighlight ? "c-navbar-highlight": ""}`}>
          {linkTextDesktop}
        </Link>
      )}

      {items && (
        <div
          className="uk-navbar-dropdown"
          uk-drop="mode: click; boundary: !section; boundary-align: true; pos: bottom-justify;"
        >
          <div className="uk-container c-navigation__dropdown">
            <h2 className="uk-text-default uk-margin-remove">
              <Link
                to={linkPath}
                className="uk-text-bold uk-width-1-1 c-navigation__heading uk-visible@l"
              >
                {linkTextDesktop}{" "}
                <span uk-icon="icon: chevron-right; ratio: 0.8"></span>
              </Link>
            </h2>
            <div className="uk-navbar-dropdown-nav uk-grid-small" uk-grid="">
              <div className="uk-width-1-1@l uk-width-1-1">
                <div className="uk-width-1-1">
                  <div className="c-navigation__list">
                    <ul
                      className="uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@xl uk-grid-small"
                      uk-grid=""
                    >
                      {items.map((item: DropdownItem, index: number) => (
                        <li
                          className="c-navigation__item"
                          key={`dropdown_item_${index}`}
                        >
                          <article>
                            <Link
                              to={item.linkPath}
                              className="uk-width-1-1 uk-flex uk-flex-top uk-flex-center uk-link-toggle"
                            >
                              <div className="uk-overflow-hidden uk-margin-right uk-flex-none">
                                <div className="uk-cover-container">
                                  <canvas
                                    width={140}
                                    height={105}
                                    className="k-hidden uk-visible@s"
                                  />
                                  <canvas
                                    width={110}
                                    height={82}
                                    className="uk-hidden@s"
                                  />
                                  <img
                                    data-src={withPrefix(item.imagePath)}
                                    alt={item.imageAlt}
                                    uk-cover=""
                                    uk-img=""
                                  />
                                </div>
                              </div>

                              <div className="uk-flex-auto">
                                <h2 className="uk-h6 uk-text-bold uk-margin-remove-bottom uk-margin-small-top@s uk-link-heading">
                                  {item.heading}
                                </h2>
                                <p className="uk-h6 c-text-xsmall uk-margin-remove">
                                  {item.subheading}
                                </p>
                                <div
                                  className="uk-text-bold c-text-xsmall uk-link c-icon-right-arrow uk-margin-small-bottom"
                                  style={{ marginTop: "4px" }}
                                >
                                  {item.linkText}
                                </div>
                              </div>
                            </Link>
                          </article>
                          <div className="c-navigation__divider" />
                        </li>
                      ))}
                      {linkTextMobile && (
                        <li
                          className="uk-width-auto c-navigation__item uk-margin-auto"
                          style={{ minHeight: "0" }}
                        >
                          <div className="uk-padding-small uk-padding-remove-left">
                            <Link
                              to={linkPath}
                              className="uk-button uk-button-primary uk-width-1-1"
                            >
                              {linkTextMobile}
                            </Link>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default NavbarDropdownArticle;
