import React from "react";
import { Link } from "gatsby";

// components
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarDropdown from "./NavbarDropdown";

// assets
import bpress from "../../../assets/menu/thumb-bpress.jpg";
import bpressXl from "../../../assets/menu/thumb-bpress-xl.jpg";
import bpressMaxiPro from "../../../assets/menu/thumb-bpress-maxipro.jpg";

const productItems = [
  // {
  //   id: 1,
  //   imgSrc: "/menu/thumb-bpress.jpg",
  //   imgAlt: "bpress",
  //   title: "Products",
  //   subtitle: `Search the range`,
  //   viewMore: "/products/?brand=>B<+Press",
  //   pageUrl: "/products/?brand=>B<+Press",
  //   pageUrlLabel: "View range",
  // },
  {
    id: 1,
    imgSrc: bpress,
    imgAlt: "bpress",
    title: ">B< Press",
    subtitle: "&frac12;″ - 2″",
    viewMore: "/products/?brand=>B<+Press",
    pageUrl: "/products/b-press",
  },
  {
    id: 2,
    imgSrc: bpressMaxiPro,
    imgAlt: "bpress",
    title: ">B< MaxiPro",
    subtitle: "&frac14;″ - 1&frac38;″",
    viewMore: "/products/?brand=>B<+MaxiPro",
    pageUrl: "/products/b-maxipro",
  },
  {
    id: 2,
    imgSrc: bpressXl,
    imgAlt: "bpressxl",
    title: ">B< Press XL",
    subtitle: "2&frac12;″ - 4″",
    viewMore: "/products/?brand=>B<+Press+XL",
    pageUrl: "/products/b-press-xl",
  },
];

const ProductNav: React.FC = () => {
  return (
    <NavbarDropdown
      title="Products"
      url="/products"
      navigation={<ProductNavItems />}
      quicklinks={<QuickLinks />}
    />
  );
};

const ProductNavItems: React.FC = () => {
  return (
    <>
      {productItems.map((item, index: number) => (
        <NavbarDropdownItem
          key={`product_item_${index}`}
          id={`js-scroll-products-${item.id}`}
          imgSrc={item.imgSrc}
          imgAlt={item.imgAlt}
          title={item.title}
          subtitle={item.subtitle}
          viewMore={item.viewMore}
          pageUrl={item.pageUrl}
        />
      ))}
    </>
  );
};

const QuickLinks: React.FC = () => {
  return (
    <>
      {/* item */}
      <div className="c-navigation__item uk-text-center">
        <div className="uk-width-2-3 uk-height-1-1 uk-margin-auto uk-flex uk-flex-middle uk-flex-center">
          <div className="uk-flex-auto">
            <strong className="uk-margin-remove-bottom uk-display-block uk-h6 uk-text-bold">
              FAQs
            </strong>
            <p className="uk-margin-remove uk-h6 uk-text-small">
              Get answers quick. Check out our frequently asked questions
            </p>
            <Link
              to="/resources/faqs"
              className="uk-display-inline-block uk-text-bold uk-text-small uk-link c-icon-right-arrow"
            >
              Find Out More
            </Link>
          </div>
        </div>
        <div className="c-navigation__divider uk-visible@l" />
      </div>
    </>
  );
};

export default ProductNav;
