import React from "react";
import { Link } from "gatsby";

const MobileNav: React.FC = () => {
  return (
    <>
      <li className="uk-hidden@l c-navigation__mobile">
        <Link to="/about">
          <svg
            width="14"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.667 9.667H7V7h-.667M7 4.333h.007M13 7A6 6 0 111 7a6 6 0 0112 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          About Us
        </Link>
      </li>
      <li className="uk-hidden@l c-navigation__mobile">
        <Link to="/contact">
          <svg
            width="14"
            height="12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 3.333L6.26 6.84a1.333 1.333 0 001.48 0L13 3.333M2.333 10.666h9.334A1.333 1.333 0 0013 9.333V2.666a1.334 1.334 0 00-1.333-1.333H2.333A1.333 1.333 0 001 2.666v6.667a1.333 1.333 0 001.333 1.333z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          Contact
        </Link>
      </li>
      <li className="uk-hidden@l c-navigation__mobile">
        <a
          href="https://conexbanninger.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            width="14"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 7a6 6 0 01-6 6m6-6a6 6 0 00-6-6m6 6H1m6 6a6 6 0 01-6-6m6 6c1.105 0 2-2.687 2-6s-.895-6-2-6m0 12c-1.105 0-2-2.687-2-6s.895-6 2-6m0 0a6 6 0 00-6 6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          Conex Bänninger Worldwide
        </a>
      </li>
    </>
  );
};

export default MobileNav;
